import { Slider } from "@mui/material";

const Layout = ({ 
    layout, 
    setLayout,
    colors
}) => {
    // Funções para atualizar cada propriedade do layout
    const handleGapChange = (e, newValue) => setLayout({ ...layout, gap: newValue });
    const handleBorderWidthChange = (e, newValue) => setLayout({ ...layout, borderWidth: newValue });
    const handleBorderRadiusChange = (e, newValue) => setLayout({ ...layout, borderRadius: newValue });
    const handleCardPaddingChange = (e, newValue) => setLayout({ ...layout, cardPadding: newValue });
    const handleBorderStyleChange = (e) => setLayout({ ...layout, borderStyle: e.target.value });

    // Função para formatar o valor exibido nos sliders
    const formatValue = (value) => `${value}px`;

    return (
        <fieldset className="form-section square__2" style={{ backgroundColor: colors.cards }}>
            {/* Espaçamentos */}
            <div className="square__dv col">
                <label style={{color: colors.letter}}>Espaçamento entre cartões: {layout.gap}</label>
                <Slider
                    aria-label="Espaçamento"
                    value={layout.gap}
                    onChange={handleGapChange}
                    getAriaValueText={formatValue}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={20}
                    sx={{
                        color: colors.letter,
                        "& .MuiSlider-thumb": { backgroundColor: colors.background },
                        "& .MuiSlider-track": { backgroundColor: colors.details },
                        "& .MuiSlider-rail": { backgroundColor: colors.background },
                        "& .MuiSlider-valueLabel": { color: colors.letter },
                        "& span": { color: colors.details },
                    }}
                />

                <label style={{color: colors.letter}}>Espaçamento interno dos cartões: {layout.cardPadding}</label>
                <Slider
                    aria-label="Espaçamento Interno"
                    value={layout.cardPadding}
                    onChange={handleCardPaddingChange}
                    getAriaValueText={formatValue}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={5}
                    max={30}
                    sx={{
                        color: colors.letter,
                        "& .MuiSlider-thumb": { backgroundColor: colors.background },
                        "& .MuiSlider-track": { backgroundColor: colors.details },
                        "& .MuiSlider-rail": { backgroundColor: colors.background },
                        "& .MuiSlider-valueLabel": { color: colors.letter },
                        "& span": { color: colors.details },
                    }}
                />
            </div>

            {/* Cantos Arredondados */}
            <div className="square__dv col">
                <label style={{color: colors.letter}}>Cantos arredondados: {layout.borderRadius}</label>
                <Slider
                    aria-label="Cantos Arredondados"
                    value={layout.borderRadius}
                    onChange={handleBorderRadiusChange}
                    getAriaValueText={formatValue}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={20}
                    sx={{
                        color: colors.letter,
                        "& .MuiSlider-thumb": { backgroundColor: colors.background },
                        "& .MuiSlider-track": { backgroundColor: colors.details },
                        "& .MuiSlider-rail": { backgroundColor: colors.background },
                        "& .MuiSlider-valueLabel": { color: colors.letter },
                        "& span": { color: colors.details },
                    }}
                />
            </div>

            {/* Bordas */}
            <div className="square__dv col">
                <label style={{color: colors.letter}}>Espessura da borda: {layout.borderWidth}</label>
                <Slider
                    aria-label="Espessura da Borda"
                    value={layout.borderWidth}
                    onChange={handleBorderWidthChange}
                    getAriaValueText={formatValue}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={10}
                    sx={{
                        color: colors.letter,
                        "& .MuiSlider-thumb": { backgroundColor: colors.background },
                        "& .MuiSlider-track": { backgroundColor: colors.details },
                        "& .MuiSlider-rail": { backgroundColor: colors.background },
                        "& .MuiSlider-valueLabel": { color: colors.letter },
                        "& span": { color: colors.details },
                    }}
                />

                <label style={{color: colors.letter}}>Estilo da borda:</label>
                <select
                    style={{backgroundColor: colors.background, color: colors.letter}}
                    className="input__typeBorder"
                    value={layout.borderStyle}
                    onChange={handleBorderStyleChange}
                >
                    <option value="none">Nenhum</option>
                    <option value="solid">Sólida</option>
                    <option value="dotted">Pontilhada</option>
                    <option value="dashed">Tracejada</option>
                    <option value="double">Dupla</option>
                </select>
            </div>

            
        </fieldset>
    );
};

export default Layout;