import "./profile.css";
import ImageProfile from "../../../../../../assets/logo/Logo.png";
import { Link } from "react-router-dom";
import { MdCheck, MdClose, MdEdit } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { doUpdateForm } from "../../../../../../firebase/docs";
import { uploadImagePost } from "../../../../../../firebase/post";
import { deleteImageProfile, uploadImageProfile } from "../../../../../../firebase/profile";
import { BiLogoInstagram } from "react-icons/bi";
import { TbWorldWww } from "react-icons/tb";
import { motion } from "framer-motion";

const Profile = (prop) => {

    const [image, setImage] = useState(prop.imageFoc);
    const [imageFile, setImageFile] = useState(null);

    const fileInputRef = useRef(null);

    const [formValues, setFormValues] = useState({
        imgProfile: "",
    })

    useEffect(() => {
        setImage(prop.imageFoc);
    }, [prop.imageFoc]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            // Verificar o tipo do arquivo (somente imagens)
            const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
            if (!validImageTypes.includes(file.type)) {
                alert("Por favor, selecione uma imagem válida (JPEG, PNG, GIF ou WEBP).");
                return;
            }
    
            // Verificar o tamanho do arquivo (máximo 5MB)
            const maxSizeInMB = 7;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // 7MB
            if (file.size > maxSizeInBytes) {
                alert(`A imagem deve ter no máximo ${maxSizeInMB}MB.`);
                return;
            }

            // Ler o arquivo e armazenar no estado
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageFile({
                    preview: e.target.result,
                    file: file,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const resetImageChange = () => {
        setImageFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const handleSubmitProfilePhoto = async (e) => {
        e.preventDefault();
        const dataForm = {}

        if (prop.nameImgProfile) {
            await deleteImageProfile(prop.imgProfile, prop.nameImgProfile);
        }
        
        if (imageFile?.file) {
            const imgProfile = await uploadImageProfile(imageFile.file);
            const nameImgProfile = imageFile.file.name;
            console.log("Url da imagem enviado", imgProfile);
            dataForm.imgProfile = imgProfile;
            dataForm.nameImgProfile = nameImgProfile;
        }

        try {
            await doUpdateForm(dataForm);
        } catch (er) {
            console.error(er);
        } finally {
            alert("Imagem de perfil salva com sucesso!")
        }
    }

    const resetImageProfile = (e) => {
        e.preventDefault();
        setImageFile(null);
    }

    
    return (
        <motion.div 
            initial={{scale: 0}}
            animate={{scale: 1}}
            className="profile">

            <div className="profile__junt">
            
                <div className="profile__photo col">
                    {!image ? (
                        <img 
                            src={prop.imgProfile || ImageProfile}
                            alt={prop.nameImgProfile}/>
                        ) : (
                            <div className="profile__image__edit">
                                <label htmlFor="imageProfileEdit"
                                    onClick={(e) => {
                                        if (imageFile) {
                                            e.preventDefault();
                                            resetImageChange();
                                        }
                                    }}>

                                    <img 
                                        src={imageFile?.preview || prop.imgProfile || ImageProfile}
                                        alt="Imagem de modelo" />

                                    Trocar imagem

                                </label>
                                {imageFile &&
                                    <div className="save__photo">
                                        <button 
                                            onClick={resetImageProfile}
                                            className="close__photo__profile"><MdClose /></button>
                                        <button 
                                            onClick={handleSubmitProfilePhoto}
                                            className="save__photo__profile"><MdCheck /></button>
                                        
                                    </div>
                                }
                                <input className="imageProfile__edit" type="file" accept="image/*" name="imageProfileEdit" id="imageProfileEdit" onChange={handleImageChange}/>
                            </div>
                        )
                    }
                </div>

                <div className="profile__info col">
                    <div className="profile__info__title">
                        <div className="profile__info__name">
                            <h3><span>{prop.username}</span></h3>
                            <p>{prop.obs}</p>
                        </div>
                        
                        <div className="profile__info__plus col">
                            <p>{prop.nicho}</p>
                            <p>{prop.emailContato}</p>

                            <div className="social-media">
                                {prop?.instagram &&
                                    <Link 
                                        target="_blank"
                                        to={`https://www.instagram.com/${prop.instagram}`}><BiLogoInstagram /></Link>
                                }
                                {prop?.website &&
                                    <Link 
                                        target="_blank"
                                        href={`${prop.website}`}><TbWorldWww /></Link>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="profile__info__button row">
                    
                <p>{prop.func}</p>
                <button onClick={prop.edit}>
                    <MdEdit />
                </button>

            </div>

        </motion.div>
    );
}

export default Profile;