import './botLink.css';

import { useEffect, useState } from "react";
import { ButtonLink } from "../../../../components/button/button";
import { FaCopy } from "react-icons/fa6";
import Message from "../../../../components/alerts/message";
import { useAuth } from '../../../../contexts/contexts';
import { doGetDocumentOnStorage } from '../../../../firebase/docs';

const BotLink = (prop) => {
    const [user, setUser] = useState(null);
    const [txtCopy, setTxtCopy] = useState("");
    const [isCopy, setIsCopy] = useState(false);
    const [alertStatus, setAlertStatus] = useState(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) return; // Certifique-se de que o usuário está autenticado e o username está definido
    
        const fetchUuid = async () => {
            try {
                const getDoc = await doGetDocumentOnStorage();

                if (getDoc?.nomeEmpresa) {
                    setUser(getDoc.username);
                    setTxtCopy(`https://www.desenholy.com/${user}`);
                } else {
                    console.log("Não foi possivel encontrar o uuid");
                }

            } catch (err) {
                console.log(err.code)
            }
        }

        fetchUuid();
    }, [user, currentUser]);

    const handleCopy = async () => {
        try {
            const copy = await navigator.clipboard.writeText(txtCopy); // Tenta copiar o link
            if (copy) {
                setAlertStatus("success");
            } else {
                setAlertStatus("fail");
            }
        } catch (err) {
            console.error("Erro ao copiar:", err);
            setAlertStatus("fail");
        }

        setIsCopy(true);

        // Remover o alerta após 8 segundos
        setTimeout(() => {
            setIsCopy(false);
            setAlertStatus(null);
        }, 5000);
    };

    return (
        <div className="copy__link">
            {user ? (
                <ButtonLink
                    className={prop.className}
                    icon={<FaCopy />}
                    value={`Aperte aqui para copiar seu link`}
                    onClick={handleCopy}
                    readOnly
                />
            ) : (
                <p>Carregando...</p>
            )}

            {isCopy && alertStatus && (
                <>
                    <br />
                    <Message
                        emoji={alertStatus === "success" ? "sad" : "grin"}
                        className={`${alertStatus === "success" ? "alert-fail" : "alert-success"}`}
                        title={alertStatus === "success" ? "Erro" : "Link Copiado"}
                        text={alertStatus === "success" ? "Algo deu errado ao copiar o link. Tente novamente mais tarde" : "Compartilhe o link onde você desejar!"}
                    />
                </>
            )}
        </div>
    );
};

export default BotLink;
