import './servicos.css';
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { motion } from "framer-motion";
import { BiArrowFromLeft, BiArrowFromRight, BiPlus } from "react-icons/bi";

import { useAuth } from "../../../../contexts/contexts";
import { ButtonLink } from "../../../../components/button/button";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { deleteCategory, deleteCategoryAndServices, fetchAllCategory, insertCategory } from '../components/service/ctxt/collectionServicos';
import BotLink from '../components/botLink';

const Servicos = () => {
    const { 
        loading, 
        currentUser, 
        mobile } = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    // Variantes para animação
        const sidebarVariants = {
            open: {
            x: 0, // Posição final
            transition: { type: "spring", stiffness: 300, damping: 30 },
            },
            closed: {
            x: "-100%", // Fora da tela (lado direito)
            transition: { type: "spring", stiffness: 300, damping: 30 },
            },
        };
    
    // Location
        const navigate = useNavigate();
        const location = useLocation();
        const path = decodeURIComponent(location.pathname).split("/").pop();

    // consts
        const [btnAdd, setBtnAdd] = useState(false);
        const [imageFile, setImageFile] = useState(null);
        const [imagePreview, setImagePreview] = useState(null);
        const [alertStatus, setAlertStatus] = useState(null);

    // Buscar Categorias
        const [allCategories, setAllCategories] = useState([]);

    // Criar Categorias
        const [editClass, setEditClass] = useState(false);
        const [className, setClassName] = useState("");

    const [formValues, setFormValues] = useState({
        title: "",
        desc: "",
        price: "",
        imgURL: ""
    });

    // Navegar para login se o usuário não estiver autenticado
    useEffect(() => {
        
        if (!loading && !currentUser) {
            navigate("/entrar");
        }
    }, [loading, currentUser, navigate]);

    useEffect(() => {
        const allCategories = async () => {
            try {
                const data = await fetchAllCategory();
                setAllCategories(data);
            } catch(error) {
                console.log(error);
            }
        }
        allCategories();
    }, [path]);

    // Verificar se todos os campos estão preenchidos
    useEffect(() => {
        setBtnAdd(formValues.title && formValues.desc && formValues.price && imageFile);
    }, [formValues, imageFile]);

    // Atualizar a mensagem de alerta após 5 segundos
    useEffect(() => {
        if (alertStatus) {
            const timer = setTimeout(() => setAlertStatus(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [alertStatus]);

    useEffect(() => {
        return () => {
            if (imagePreview) URL.revokeObjectURL(imagePreview);
        };
    }, [imagePreview]);

    const handleEditClass = (e) => {
        e.preventDefault();
        setEditClass((prev) => !prev);
    }

    const handleAddClass = async (e) => {
        e.preventDefault();

        if (!className) return;

        try {
            const confirm = window.confirm("Deseja adicionar essa categoria?");
            if (confirm) {
                await insertCategory(className);
                setEditClass((prev) => !prev);

                // adicionando a categoria manualmente
                setAllCategories((prevCategories) => [
                    ...prevCategories,
                    { categoria: className }
                ]);

            }
        } catch (err) {
            console.error(err.message);
            
        }
    }

    const handleDeleteClass = async (nome) => {
        try {
            const confirm = window.confirm(`Você deletará permanentemente a categoria: ${nome}?`);
            if (confirm && nome) {
                await deleteCategoryAndServices(nome);
                window.location.reload();
            } else {
                console.log("Cancelado!");
            }
        } catch (err) {
            console.error(err.message);
            
        }
    }

    return (
        <div className="upload__container">
            <Helmet>
                <title>Desenholy - Serviços</title>
            </Helmet>
            
            {!mobile &&
                <motion.div className={mobile ? "upload__class__mobile":"upload__class"}>
                    <div className='class'>
                        <div className='class__title'>
                            <BotLink />
                        </div>
                        <div className='class__title'>
                            <p>Categorias</p>
                        </div>
                        <div className='class__items'>
                            <div className='class__d__items'>
                                {allCategories.map((category) => (
                                    <div key={category.id} className='row'>
                                    
                                        <ButtonLink 
                                            to={category.categoria}
                                            value={category.categoria}
                                            className={category.categoria === path ? "active__class--item" : "class--item"}
                                        />
                                        <button 
                                            onClick={() => handleDeleteClass(category.id)}
                                            className='class__delete'>
                                                <MdClose />
                                        </button>

                                    </div>
                                ))}
                                {allCategories.length === 0 &&
                                    <p>Você ainda não adicionou nenhuma categoria.</p>
                                }
                            </div>

                            <button 
                                onClick={handleEditClass}
                                className='class__addBtn'>
                                <BiPlus /> Adicionar Categoria
                            </button>

                        </div>
                        {editClass && 
                            <div className='class__add'>
                                <div className='class__add__input'>
                                    <input 
                                        value={className}
                                        onChange={(e) => setClassName(e.target.value.trim())}
                                        placeholder='Digite o nome da categoria...'/>
                                </div>
                                <motion.form 
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0}}
                                    onSubmit={handleAddClass}
                                    className='class__add__btns'>

                                    <button 
                                        type='submit'
                                        onClick={handleEditClass}>
                                        <MdClose />
                                    </button>
                                    <button>Criar</button>

                                </motion.form>
                            </div>
                        }
                        <button onClick={() => setIsOpen(!isOpen)} className='arrow'>
                            {isOpen ? <BiArrowFromRight /> : <BiArrowFromLeft />}
                        </button>
                    </div>
                </motion.div>
            }

            {mobile &&
                <motion.div 
                    variants={mobile && sidebarVariants}
                    initial={mobile ? "open" : "closed"}
                    animate={isOpen && mobile ? "open" : "closed"}
                    className={mobile ? "upload__class__mobile":"upload__class"}>

                    <div className='upload__class__height'></div>
                    <div className='class'>
                        <div className='class__title'>
                            <BotLink />
                        </div>
                        <div className='class__title'>
                            <p>Categorias</p>
                        </div>
                        <div className='class__items'>
                            <div className='class__d__items'>
                                {allCategories.map((category) => (
                                    <div key={category.id} className='row'>
                                    
                                        <ButtonLink 
                                            to={category.categoria}
                                            value={category.categoria}
                                            className={category.categoria === path ? "active__class--item" : "class--item"}
                                        />
                                        <button 
                                            onClick={() => handleDeleteClass(category.id)}
                                            className='class__delete'>
                                                <MdClose />
                                        </button>

                                    </div>
                                ))}
                                {allCategories.length === 0 &&
                                    <p>Você ainda não adicionou nenhuma categoria.</p>
                                }
                            </div>

                            <button 
                                onClick={handleEditClass}
                                className='class__addBtn'>
                                <BiPlus /> Adicionar Categoria
                            </button>

                        </div>
                        {editClass && 
                            <div className='class__add'>
                                <div className='class__add__input'>
                                    <input 
                                        value={className}
                                        onChange={(e) => setClassName(e.target.value.trim())}
                                        placeholder='Digite o nome da categoria...'/>
                                </div>
                                <motion.form 
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0}}
                                    onSubmit={handleAddClass}
                                    className='class__add__btns'>

                                    <button 
                                        type='submit'
                                        onClick={handleEditClass}>
                                        <MdClose />
                                    </button>
                                    <button>Criar</button>

                                </motion.form>
                            </div>
                        }
                        <button onClick={() => setIsOpen(!isOpen)} className='arrow'>
                            {isOpen ? <BiArrowFromRight /> : <BiArrowFromLeft />}
                        </button>
                    </div>
                    <div className='upload__class__height'></div>

                </motion.div>
            }
            
            
            <div className='upload__class__container'>
                {path === "servicos" ? (
                    <div className="add__text">
                        <h1>Adicione as categorias</h1>
                        <h2>
                            Organize seus produtos pelas categorias. Assim fica mais organizado seus <span>serviços/produtos</span>
                        </h2>
                    </div>
                ) : (
                    <Outlet />
                )}
            </div>
        </div>
    );
};

export default Servicos;
