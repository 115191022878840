import React from "react";

const Colors = ({ colors, updateColor, template, setTemplate }) => {
    // Função para atualizar o template selecionado
    const handleTemplateChange = (e) => {
        const selectedTemplate = e.target.value;
        setTemplate(selectedTemplate);
    };

    // Função para atualizar uma cor específica
    const handleColorChange = (key) => (e) => {
        updateColor(key, e.target.value);
    };

    // Opções de cores para personalização
    const colorOptions = [
        { label: "Fundo", key: "background", value: colors.background },
        { label: "Cartões", key: "cards", value: colors.cards },
        { label: "Bordas", key: "border", value: colors.border },
        { label: "Letras", key: "letter", value: colors.letter },
        { label: "Letras do botão", key: "letterButton", value: colors.letterButton },
        { label: "Detalhes", key: "details", value: colors.details },
        { label: "Títulos", key: "title", value: colors.title },
    ];

    return (
        <fieldset className="form-section square__1" style={{ backgroundColor: colors.cards}}>
            {/* Seletor de templates pré-definidos */}
            <div className="square__dv">
                <label style={{color: colors.letter}}>Predefinições</label>
                <select style={{backgroundColor: colors.background, color: colors.letter}}
                    value={template}
                    onChange={handleTemplateChange}
                >
                    <option value="personalizado">Personalizado</option>
                    <option value="desenholy">Desenholy</option>
                    <option value="oceano">Oceano</option>
                    <option value="claridade">Claridade</option>
                    <option value="noite">Noite</option>
                    <option value="natureza">Natureza</option>
                    <option value="floresta">Floresta</option>
                    <option value="rosada">Rosada</option>
                </select>
            </div>

            {/* Inputs de cores para personalização */}
            {colorOptions.map(({ label, key, value }, index) => (
                <div key={index} className="square__dv row">
                    <label style={{color: colors.letter}} htmlFor={key}>{label}</label>
                    <input
                        id={key}
                        type="color"
                        className={`colors ${key}-color`}
                        value={value}
                        onChange={handleColorChange(key)}
                    />
                </div>
            ))}
        </fieldset>
    );
};

export default Colors;