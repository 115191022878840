import { useEffect, useState } from "react";
import { doGetDocumentOnStorage } from "../../../../../firebase/docs";
import { useAuth } from "../../../../../contexts/contexts";
import { TbWorldWww } from "react-icons/tb";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const CardPreview = ({ colors, layout, texts }) => {
    const { mobile } = useAuth();
    const [user, setUser] = useState({});

    // Carrega os dados do usuário ao montar o componente
    useEffect(() => {
        const fetchData = async () => {
            const data = await doGetDocumentOnStorage();
            setUser({
                name: data.nomeEmpresa,
                image: data.imgProfile,
                nameImage: data.nameImgProfile,
                description: data.observacoesAdicionais,
                type: data.tipoEmpresa,
            });
        };
        fetchData();
    }, []);

    // Estilos dinâmicos baseados nas props
    const containerStyle = {
        backgroundColor: colors.background,
        borderColor: colors.border,
        borderWidth: layout.borderWidth,
        borderRadius: layout.borderRadius,
        borderStyle: layout.borderStyle,
        gap: layout.gap,
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        padding: "16px",
    };

    const imageStyle = {
        width: "150px",
        height: "150px",
        borderRadius: layout.borderRadius,
    };

    const cardStyle = {
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.cards,
        borderWidth: layout.borderWidth,
        borderColor: colors.border,
        borderStyle: layout.borderStyle,
        borderRadius: layout.borderRadius,
        padding: layout.cardPadding,
    };

    const buttonStyle = {
        padding: "4px 8px",
        backgroundColor: colors.details,
        color: colors.letterButton,
        borderRadius: layout.borderRadius,
        border: "none",
        cursor: "pointer",
    };

    const linkStyle = {
        color: colors.details,
        textDecoration: "none",
    };

    return (
        <fieldset style={containerStyle}>
            {/* Imagem do perfil */}
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", minWidth: "150px", width: mobile ? "100%" : "150px", minHeight: "150px", height: "auto", maxHeight: "150px" }}>
                <img
                    style={imageStyle}
                    src={user.image}
                    alt={user.nameImage}
                />
            </div>

            {/* Informações do perfil */}
            <div style={cardStyle}>
                <h3 style={{ color: colors.title, fontSize: texts.titleSize }}>{user.name}</h3>
                <p style={{ color: colors.letter, fontSize: `${texts.textSize}pt` }}>
                    {user?.description ||
                        "Esse é apenas um cartão de pré-visualização. Lembre-se de preencher os seus campos do seu perfil."}
                </p>
                <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                    <FaInstagram style={{ fontSize: "1.8rem", color: colors.details }} />
                    <TbWorldWww style={{ fontSize: "1.8rem", color: colors.details }} />
                </div>
            </div>

            {/* Mensagem de boas-vindas */}
            <div style={cardStyle}>
                <i style={{ color: colors.details }}>Seja bem-vindo {user.name}</i>
                <p style={{ color: colors.letter, fontSize: `${texts.textSize}pt`, lineHeight: texts.lineHeight }}>
                    Esse é apenas um cartão de pré-visualização. Se ainda não preencheu suas informações no seu perfil,{" "}
                    <Link style={linkStyle} to="/perfil">clique aqui</Link>.
                </p>
            </div>

        </fieldset>
    );
};

export default CardPreview;