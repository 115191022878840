import { collection, deleteDoc, doc, getCountFromServer, getDocs, getFirestore, query, runTransaction, setDoc, where } from "firebase/firestore";
import { auth, db } from "../../../../../../firebase/init"
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// Referência
const getCollectionServicosRef = () => {
    const user = auth.currentUser;
    if (!user) throw new Error("Usuário não autenticado!");
    return collection(db, "users", user.uid, "servicos");
};

const getCollectionCategoryRef = () => {
    const user = auth.currentUser;
    if (!user) throw new Error("Usuário não autenticado!");
    return collection(db, "users", user.uid, "categorias");
};

export const sizeServices = async (path) => { //
    try {
        const servicesRef = getCollectionServicosRef();
        const q = query(servicesRef, where("categoria", "==", path));
        const snapshot = await getDocs(q);
        return snapshot;
    } catch (error) {
        console.log(error)
    }
}

export const insertCategory = async (nameCategory) => { // Inserindo uma NOVA CATEGORIA
    try {
        const categoryCollectionRef = getCollectionCategoryRef();
        
        // Verificando se a categoria já existe. Para isso, vamos buscar documentos na coleção
        const querySnapshot = await getDocs(categoryCollectionRef);
        const categoryExists = querySnapshot.docs.some(doc => doc.id === nameCategory);
        
        if (!categoryExists) {
            // Se a categoria não existir, cria o documento
            const docRef = doc(categoryCollectionRef, nameCategory);
            await setDoc(docRef, {
                categoria: nameCategory,
                createdAt: new Date().toISOString(),
            });
            console.log("Categoria criada com sucesso!");
        } else {
            console.log("Categoria já existe!");
        }
    } catch (err) {
        console.error("Erro ao inserir categoria:", err);
    }
};

export const insertService = async (path, dataService) => { // Inserindo um NOVO SERVIÇO
    try {
        const servicesRef = getCollectionServicosRef();
        const q = query(servicesRef, where("categoria", "==", path));
        const snapshot = await getDocs(q);

        if (snapshot.size >= 8) {
            throw new Error("Você atingiu o número máximo de serviços nessa categoria.");
        }
        
        const docRef = doc(servicesRef);

        const serviceData = {
            ...dataService,
            categoria: path,
        }

        await setDoc(docRef, serviceData);
    } catch (err) {
        console.error(err.message);
        
    }
};

export const updateService = async (docId, dataService) => { // Atualiza os serviços
    try {
        const docRef = doc(getCollectionServicosRef(), docId); // Referencia o documento existente pelo ID

        const serviceData = {
            ...dataService,
        };

        await setDoc(docRef, serviceData, { merge: true });
    } catch (err) {
        console.error(err.message);
        return { success: false, error: err.message };
    }
};

export const fetchAllCategory = async () => { // Busca a categoria comparando com o location.pathname
    try {
        const docRef = getCollectionCategoryRef();
        const querySnapshot = await getDocs(docRef);
        const categories = [];
        
        // Para cada categoria, mapeia e insere em um array
        querySnapshot.forEach((doc) => {
            categories.push({id: doc.id, ...doc.data()});
        });
        
        return categories;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchService = async (path) => { // Busca Serviços
    try {
        const servicosRef = getCollectionServicosRef();
        const q = query(servicosRef, where("categoria", "==", path));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot) {
            throw new Error("Não foi possivel encontrar esses serviços.");
        }

        const services = [];
        querySnapshot.forEach((doc) => {
            services.push({
                id: doc.id,
                ...doc.data(),
            });
        });

        return services;
    } catch (err) {
        console.error("Erro ao buscar serviço:", err);
        return []; // Retorna um array vazio em caso de erro
    }
};

export const deleteService = async (servId, nameImg) => {
    try {
        const user = auth.currentUser;

        if (!user) {
            throw new Error("Usuário não autenticado.");
        }

        const storage = getStorage();
        const servicosRef = getCollectionServicosRef();

        // Referências do Firebase
        const imageRef = ref(storage, `servicos/${user.uid}/${nameImg}`);
        const docRef = doc(servicosRef, servId);

        // Exclui a imagem no Firebase Storage
        await deleteObject(imageRef);
        console.log("Imagem excluída com sucesso:", nameImg);

        // Exclui o documento no Firestore
        await deleteDoc(docRef);
        console.log("Serviço excluído com sucesso:", servId);

        return true;
    } catch (err) {
        console.error("Erro ao excluir serviço:", err);
        return false; // Retorna false em caso de erro
    }
};

export const deleteCategoryAndServices = async (categoryId) => {
    try {
        const user = auth.currentUser;

        if (!user) {
            throw new Error("Usuário não autenticado.");
        }

        const storage = getStorage();
        const servicosRef = getCollectionServicosRef(); // Função para obter referência da coleção "serviços"
        const categoriasRef = getCollectionCategoryRef(); // Função para obter referência da coleção "categorias"

        // 1. Buscar todos os serviços associados à categoria
        const q = query(servicosRef, where("categoria", "==", categoryId));
        const querySnapshot = await getDocs(q);

        // 2. Iterar sobre os serviços encontrados e deletá-los
        const deletePromises = querySnapshot.docs.map(async (docSnap) => {
            const serviceId = docSnap.id;
            const serviceData = docSnap.data();

            // Referência da imagem no Firebase Storage
            const imageRef = ref(storage, `servicos/${user.uid}/${serviceData.imgURL.name}`);

            // Excluir a imagem
            await deleteObject(imageRef);
            console.log("Imagem excluída com sucesso:", serviceData.imgURL.name);

            // Excluir o documento no Firestore
            await deleteDoc(doc(servicosRef, serviceId));
            console.log("Serviço excluído com sucesso:", serviceId);
        });

        // Aguardar exclusão de todos os serviços
        await Promise.all(deletePromises);

        // 3. Excluir a categoria
        const categoryDocRef = doc(categoriasRef, categoryId);
        await deleteDoc(categoryDocRef);
        console.log("Categoria excluída com sucesso:", categoryId);

        return true;
    } catch (err) {
        console.error("Erro ao excluir categoria e serviços:", err);
        return false;
    }
};


export const deleteCategory = async (categoryId) => { // Deleta as Categorias
    try {
        const collectionRef = getCollectionCategoryRef();
        const docRef = doc(collectionRef, categoryId);

        // Excluir serviços associados à categoria
        const servicosRef = getCollectionServicosRef();
        const servicosQuery = query(servicosRef, where("categoria", "==", categoryId));
        const servicosSnapshot = await getDocs(servicosQuery);

        // Excluir todos os serviços associados
        const deleteServicesPromises = servicosSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deleteServicesPromises);

        // Obter e excluir as subcoleções da categoria
        const subcollections = ["categorias"];
        for (const subcollection of subcollections) {
            const subcollectionRef = collection(docRef, subcollection);
            const subDocs = await getDocs(subcollectionRef);

            const deleteSubDocsPromises = subDocs.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deleteSubDocsPromises);
        }

        // Excluir o documento principal da categoria
        await deleteDoc(docRef);
        return true;
    } catch (error) {
        console.error("Erro ao tentar excluir a categoria e serviços associados:", error);
        return false;
    }
};

export const uploadImage = async (file) => { 
    try {
        const user = auth.currentUser;
        const storage = getStorage();
        const storageRef = ref(storage, `servicos/${user.uid}/${file.name}`);
        
        // Faz o upload da imagem
        await uploadBytes(storageRef, file);
        
        // Obtém a URL da imagem após o upload
        const url = await getDownloadURL(storageRef);
        
        // Retorna tanto a URL quanto o nome da imagem
        return { url, name: file.name };
    } catch (error) {
        console.error('Erro ao fazer o upload da imagem:', error);
        throw new Error('Falha no upload da imagem');
    }
};