import { useNavigate } from 'react-router-dom';
import './App.css';
import BtnApp from './btnApp';
import { useAuth } from './contexts/contexts';
import Login from './pages/login/login';
import { useEffect, useState } from 'react';
import Loading from './components/loading/loading';

const App = () => {

  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    console.log(currentUser)
    try {
      if (currentUser) {
          navigate("/perfil");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setLoad(false);
      }, 2000);
    }
  }, [currentUser]);

  if (load) return <Loading />;


  return (
    <div className='App'>
      <Login />
      <BtnApp />
    </div>
  );
}

export default App;