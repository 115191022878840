import { collection, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";
import { auth, db, storage } from "./init";

export const doGetDocumentOnStorage = async () => {
    const user = auth.currentUser;
    try {
        if (!user) {
            return null; 
        }

        const docRef = doc(db, "users", user.uid); 
        const docSnapshot = await getDoc(docRef); 

        if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            return userData; 
        } else {
            return null;
        }
    } catch (err) {
        console.error(err.code, err.message); 
        return null; 
    }
};

export const getDocNameFromUsername = async (username) => {
    try {
        // Referência à coleção `users`
        const usersCollection = collection(db, "users");

        // Query para encontrar o documento com o campo `uuid` igual ao fornecido
        const q = query(usersCollection, where("username", "==", username));

        // Executa a query
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            // Pega o nome do documento (ID do documento)
            const doc = querySnapshot.docs[0];
            const docId = doc.id; // Nome do documento

            return docId; // Retorna o nome do documento
        } else {
            console.log("Nenhum documento encontrado para o UUID:", username);
            return null;
        }
    } catch (error) {
        console.error("Erro ao buscar nome do documento pelo UUID:", error);
        throw error;
    }
};

export const doSetDocumentOnStorage = async (uid, formValues) => {
    try {
        const docRef = doc(db, "users", uid);
        const setDocument = await setDoc(docRef, formValues); // 'setDocument' salva os dados diretamente

        if (setDocument) {
            alert('Seus dados foram atualizados com sucesso! :)');
        }
        return formValues;
    } catch (err) {
        console.error(err.message);
        return null;
    }
}

export const doUpdateForm = async (formValues) => {
    const user = auth.currentUser;
    if (!user) {
        alert("Usuário não autenticado!");
        return null;
    }

    try {
        const docRef = doc(db, "users", user.uid);

        // Atualiza apenas os campos preenchidos no formValues
        await updateDoc(docRef, formValues);

        return formValues;
    } catch (err) {
        console.error("Erro ao atualizar os dados: ", err.message);
        alert("Houve um problema ao atualizar seus dados.");
        return null;
    }
};

export const doUpdateSteps = async (title, formValues, limit) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            console.log("Usuário precisa estar autenticado para criar etapas");
            return null;
        }

        if (!title || !title.trim()) {
            throw new Error("O título é obrigatório.");
        }

        // Função para sanitizar o título e usá-lo como ID
        const sanitizeTitle = (title) => {
            return title
                .trim() // Remove espaços extras no início e no fim
                .replace(/[/~#\[\]*%]/g, "") // Remove caracteres proibidos
                .replace(/\s+/g, "_") // Substitui espaços por "_"
                .toLowerCase(); // Converte para minúsculas
        };

        // Gera o ID sanitizado
        const sanitizedTitle = sanitizeTitle(title);

        if (!sanitizedTitle) {
            throw new Error("O título não pode conter apenas caracteres inválidos.");
        }

        const userDocRef = doc(db, "users", user.uid);

        // Referência à subcoleção steps
        const stepsCollectionRef = collection(userDocRef, "steps");

        //Verificar se atingiu limite
        const stepSnapshot = await getDocs(stepsCollectionRef);
        const limitCount = stepSnapshot.size;

        if (limitCount >= limit) {
            throw new Error(`Você atingiu o limite de etapas do seu plano. Limite: ${limit}`);
        }

        // Referência ao documento na subcoleção "steps"
        const stepDocRef = doc(userDocRef, "steps", sanitizedTitle);

        // Dados da etapa com o campo `createdAt`
        const stepData = {
            titulo: sanitizedTitle,
            createdAt: new Date().toISOString(),
            ...formValues,
        };

        // Cria ou substitui o documento na subcoleção "steps"
        await setDoc(stepDocRef, stepData);

        return sanitizedTitle;
    } catch (err) {
        console.error("Erro ao criar etapa:", err);
        throw err;
    }
};

export const doUpdateStepCard = async (index,formValues) => {
    const user = auth.currentUser;

    if (!user) {
        console.log("Usuário precisa estar autenticado para atualizar etapas.");
        return;
    }

    try {
        // Referência ao documento do usuário
        const docRef = doc(db, "users", user.uid);

        // Atualiza o campo steps.{index} no Firestore
        await updateDoc(docRef, {
            [`steps.${index}`]: {
                ...formValues, // Atualiza os valores no campo específico
            },
        });

        console.log(`Etapa ${index} atualizada com sucesso.`);
    } catch (err) {
        console.error("Erro ao atualizar a etapa:", err.code, err.message);
        throw err; // Relança o erro para tratar no componente
    }
}

export const doUpdateDocumentOnStorage = async (uid, formValues, imageFile) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            console.log("Usuário não está autenticado.");
            return null;
        }

        const docRef = doc(db, "users", uid);

        // Gerar um identificador único para o serviço
        const serviceId = `servico_${new Date().getTime()}`;

        // Fazer o upload da imagem, se fornecida
        let imageUrl = null;
        if (imageFile && imageFile.type.startsWith('image/')) {
            const fileName = `${uid}_${new Date().getTime()}_${imageFile.name}`;
            const storage = getStorage();
            const storageRef = ref(storage, `servicos/${uid}/${fileName}`);

            await uploadBytes(storageRef, imageFile);
            imageUrl = await getDownloadURL(storageRef);

            // Verifica se o serviço já existe e se possui um array de imagens
            const docSnapshot = await getDoc(docRef);
            let existingService = docSnapshot.exists() ? docSnapshot.data().servicos?.[serviceId] : null;
            let updatedImages = existingService?.images || []; // Obtém o array de imagens existente ou inicializa um novo

            // Adiciona a nova URL da imagem ao array
            updatedImages.push(imageUrl);

            // Atualiza os dados do serviço com as novas imagens e outros valores
            formValues = { ...formValues, images: updatedImages };
        } else if (imageFile) {
            throw new Error('O arquivo não é uma imagem válida.');
        }

        // Adiciona ou atualiza o serviço dentro do campo `servicos`
        await updateDoc(docRef, {
            [`servicos.${serviceId}`]: formValues
        });

        return formValues;
    } catch (err) {
        console.error("Erro ao enviar dados:", err.message);
        alert(`Erro ao enviar dados: ${err.message}`);
        return null;
    }
};

export const doUpdateDoc = async (uid, clientData) => {
    const user = auth.currentUser;

    if (!user) {
        console.log("Você precisa estar logado para atualizar arquivos.");
        return null;
    }

    try {
        const docRef = doc(db, "users", uid);

        const clientId = `cliente_${Date.now()}`;

        const newClient = {
            [clientId]: clientData,
        };

        await updateDoc(docRef, {
            clientes: {
                ...(clientData.clientes || {}),
                ...newClient,
            },
        });

        console.log("Cliente adicionado com sucesso!");
        return true;

    } catch (error) {
        console.error("Erro ao atualizar o documento:", error);
        return false;
    }
};

export const saveClientData = async (uid, clientData) => { 
    try {
        // Garantir que os dados do cliente tenham o formato correto
        const allowedData = {
            nome: clientData.nome || "",
            whatsapp: clientData.whatsapp || "",
        };

        // Usar o nome do cliente como ID do documento dentro da subcoleção clients
        const clientDocRef = doc(db, `users/${uid}/clients`, clientData.nome);
        await setDoc(clientDocRef, allowedData);

        // Salvar ou mesclar os dados
        // await setDoc(clientDocRef, allowedData, { merge: true });

        console.log("Dados do cliente salvos com sucesso!");
    } catch (error) {
        console.error("Erro ao salvar os dados do cliente:", error);
    }
};

export const fetchUserData = async (username) => {
    try {
        if (!username) throw new Error("UUID não fornecido!");

        const docName = await getDocNameFromUsername(username);
        const usersCollection = collection(db, "users");

        const q = query(usersCollection, where("username", "==", username));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].data();
            return { userData: userDoc, uid: docName };
        } else {
            console.error("Usuário não encontrado!");
            return null;
        }
    } catch (error) {
        console.error("Erro ao buscar os dados do usuário:", error);
        throw error; // Opcional: Propaga o erro para o chamador
    }
};

export const fetchServiceUserData = async (uid) => {
    try {
        // Referência ao documento do usuário
        const userDocRef = doc(db, "users", uid); 
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
            throw new Error("Usuário não encontrado");
        }

        const userData = userDoc.data();

        // Buscar serviços da subcoleção 'servicos'
        const servicosCollectionRef = collection(userDocRef, "servicos");
        const servicosSnapshot = await getDocs(servicosCollectionRef);
        
        const servicos = servicosSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return { userData, servicos };
    } catch (err) {
        console.error("Erro ao buscar os dados do Firestore:", err);
        throw err;
    }
};

export const fetchUserCategory = async (uid) => {
    try {
        // Referência ao documento do usuário
        const userDocRef = doc(db, "users", uid); 
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists()) {
            throw new Error("Usuário não encontrado");
        }

        const userData = userDoc.data();

        // Buscar serviços da subcoleção 'servicos'
        const categoriasCollectionRef = collection(userDocRef, "categorias");
        const categoriasSnapshot = await getDocs(categoriasCollectionRef);
        
        const categorias = categoriasSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        return { userData, categorias };
    } catch (err) {
        console.error("Erro ao buscar os dados do Firestore:", err);
        throw err;
    }
};

export const doGetAllServices = async () => {
    try {
        const user = auth.currentUser;
        if (!user) {
            console.log("Usuário não está autenticado.");
            return null;
        }

        const docRef = doc(db, "users", user.uid);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            return docSnapshot.data().servicos || {}; // Retorna todos os serviços
        }

        console.log("Documento não encontrado.");
        return {};
    } catch (err) {
        console.error("Erro ao buscar dados:", err.message);
        return {};
    }
};

export const doUpdateService = async (serviceId, updatedData) => {
    try {
        const user = auth.currentUser; // Certifique-se de que o usuário está autenticado
        if (!user) throw new Error("Usuário não autenticado.");

        const docRef = doc(db, "users", user.uid); // Documento do usuário
        await updateDoc(docRef, {
            [`servicos.${serviceId}`]: updatedData // Atualiza os dados do serviço específico
        });

        console.log("Serviço atualizado:", serviceId);
    } catch (error) {
        console.error("Erro ao atualizar serviço:", error.message);
        throw error;
    }
};

export const doDeleteService = async (serviceId) => {
    try {
        const user = auth.currentUser; // Certifique-se de que o usuário está autenticado
        if (!user) throw new Error("Usuário não autenticado.");

        const docRef = doc(db, "users", user.uid); // Documento do usuário

        // Exclui o serviço removendo-o do campo `servicos`
        await updateDoc(docRef, {
            [`servicos.${serviceId}`]: deleteField()
        });

        console.log("Serviço excluído:", serviceId);
    } catch (error) {
        console.error("Erro ao excluir serviço:", error.message);
        throw error;
    }
};

export const doSetImageDoc = async (uid, formValues, imageFile) => {
    try {
        // Referência ao documento do Firestore
        const docRef = doc(db, "users", uid);

        // Referência ao caminho de armazenamento para a imagem
        const imageRef = ref(storage, `servicos/${uid}/${imageFile.name}`);

        // Faz o upload da imagem para o Storage
        await uploadBytes(imageRef, imageFile);

        // Obtém a URL de download da imagem
        const imageUrl = await getDownloadURL(imageRef);

        // Atualiza `formValues` com a URL da imagem
        const updatedFormValues = { ...formValues, imgURL: imageUrl };

        // Define o documento no Firestore com os dados atualizados
        await setDoc(docRef, updatedFormValues);

        console.log("Documento e imagem enviados com sucesso!");
    } catch (err) {
        console.error("Erro ao enviar documento e imagem:", err.message);
    }
};