import { Link } from "react-router-dom";
import { BiLogoInstagram } from "react-icons/bi";
import { TbWorldWww } from "react-icons/tb";
import { motion } from "framer-motion";

const ProfileCard = (prop) => {
    return (
        <motion.div 
            initial={{scale: 0}}
            animate={{scale: 1}}
            className="profile">

            <div className="profile__junt">
            
                <div className="profile__photo col">
                    <img 
                        src={prop.imgProfile}
                        alt={prop.nameImgProfile}/>
                </div>

                <div className="profile__info col">
                    <div className="profile__info__title">
                        <div className="profile__info__name">
                            <h3><span>{prop.nomeEmpresa}</span></h3>
                            <p>{prop.obs}</p>
                        </div>
                        
                        <div className="profile__info__plus col">
                            <p>{prop.tipoEmpresa}</p>
                            <p>{prop.emailContato}</p>

                            <div className="social-media">
                                {prop?.instagram &&
                                    <Link 
                                        target="_blank"
                                        to={`https://www.instagram.com/${prop.instagram}`}><BiLogoInstagram /></Link>
                                }
                                {prop?.website &&
                                    <Link
                                        target="_blank"
                                        to={`https://${prop.website}`}><TbWorldWww /></Link>
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="profile__info__button row">
                <p>{prop.func}</p>
            </div>

        </motion.div>
    );
}

export default ProfileCard;