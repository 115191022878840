import "./homeService.css";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchServiceUserData, fetchUserData } from "../../firebase/docs";

const HomeService = () => {
    const [service, setService] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);

    const location = useLocation();
    const path = decodeURIComponent(location.pathname).split("/").pop();
    const [val, setVal] = useState(null);
    
    const { uuid } = useParams(); // Captura a `categoria` da URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Buscar dados do usuário
                const data = await fetchUserData(uuid);
                const serviceData = await fetchServiceUserData(data.uid);

                // Salva todos os serviços no estado
                setService(serviceData.servicos);

                // Filtrar serviços pela categoria da URL
                const filtered = serviceData.servicos.filter(
                    (serv) => serv.categoria === path
                );
                setFilteredServices(filtered);

            } catch (err) {
                console.error("Erro ao buscar os dados:", err);
            }
        };
        fetchData();
    }, [uuid, path]); // Atualiza sempre que `uuid` ou `categoria` mudar

    const handleServClick = (id) => {
        setVal((prev) => prev === id ? null : id);
    }
    
    return (
        <div className="store-container">
            <h1 className="store-title">{path}</h1>
            {filteredServices.length > 0 ? (
                <div className="store-grid">
                    {filteredServices.map((serv) => (
                        <div key={serv.id} onClick={() => handleServClick(serv.id)} className={`store-card ${val === serv.id ? "store-click-card" : ""}`}>
                            {serv.imgURL && (
                                <img src={serv.imgURL.url} alt={serv.nome} className="store-image" />
                            )}
                            <h3 className="store-item-title">{serv.nome}</h3>
                            <p className="store-item-desc">{serv.desc}</p>
                            <p className="store-item-price">R$ {serv.preco}</p>
                            <p className={`store-item-status ${serv.status.toLowerCase()}`}>
                                {serv.status}
                            </p>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="store-empty">Nenhum serviço encontrado para esta categoria.</p>
            )}
        </div>
    );
    
};

export default HomeService;
