import "./fetchPost.css";
import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { addCommentOnPost, fetchAllPosts } from "../../../../../../firebase/post";
import Image1 from "../../../../../../assets/logo/Logo.png";
import { PiChatCircle, PiChatCircleFill, PiUserFocusFill } from "react-icons/pi";
import Loading from "../../../../../../components/loading/loading";
import { MdSend } from "react-icons/md";
import Like from "./components/like";
import { doGetDocumentOnStorage } from "../../../../../../firebase/docs";
import Comment from "./components/comment";
import { BiSolidShoppingBag } from "react-icons/bi";
import { Link } from "react-router-dom";

const FetchAllPost = ({ dataFilter }) => {

  const [infosUser, setInfosUser] = useState({
    imgProfile: "",
    nameUser: ""
  });

  const [comment, setComment] = useState(null); // Estado de comentários
  const [prevComment, setPrevComment] = useState([]); // Comentários anteriores
  const [fetchAllPost, setFetchAllPost] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState([]); // Posts visíveis na tela
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const [isLoading, setIsLoading] = useState(false); // Indicador de carregamento
  const [formValues, setFormValues] = useState({});
  const postRef = useRef(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const POSTS_PER_PAGE = 4;


  // Função para carregar posts
  const fetchPost = useCallback(async () => {
    setIsLoading(true);
    const fetchColPost = await fetchAllPosts();

    // Filtrar os posts com base nas tags selecionadas
    const filteredPosts = dataFilter.length > 0
      ? fetchColPost.filter((post) =>
          post.content.match(/#(\w+)/g)?.some((tag) =>
            dataFilter.includes(tag.replace("#", ""))
          )
        )
      : fetchColPost;


    // Randomizar posts
    const randomizedPosts = filteredPosts.sort(() => Math.random() - 0.5);

    setFetchAllPost(randomizedPosts); // Salva todos os posts randomizados
    setDisplayedPosts(randomizedPosts.slice(0, POSTS_PER_PAGE)); // Mostra os primeiros N posts
    setIsLoading(false);
  }, [dataFilter]);


  useEffect(() => {
    fetchPost(); // Carrega posts ao montar o componente ou ao mudar o filtro
  }, [fetchPost]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await doGetDocumentOnStorage();
      if (data) {
        setInfosUser({
          imgProfile: data.imgProfile,
          nameUser: data.nomeEmpresa,
        });
        setIsUserLoaded(true); // Marca como carregado
      }
    };

    fetchData();
  }, []);


  const loadMorePosts = () => {
    const nextPage = currentPage + 1;
    const nextPosts = fetchAllPost.slice(0, nextPage * POSTS_PER_PAGE);

    setDisplayedPosts(nextPosts); // Atualiza os posts visíveis
    setCurrentPage(nextPage); // Atualiza a página atual
  };


  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !isLoading &&
        displayedPosts.length < fetchAllPost.length
      ) {
        loadMorePosts(); // Carregar mais posts
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [displayedPosts, fetchAllPost, isLoading]);


  const removeTextRegex = (text) => {
    const regex = /#(\w+)/g;
    return text.replace(regex, "").trim();
  };


  const toggleComments = (id) => {
    setComment((prev) => (prev === id ? null : id));
  };


  const handleRegulateTextarea = (e) => {
    const { id, value } = e.target;
    const textarea = e.target;
    const currentValue = textarea.value;

    // Atualiza o campo 'content' no formValues
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
      content: currentValue,
    }));

    // Ajusta a altura do textarea dinamicamente
    textarea.style.height = "50px";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };


  const sendComment = async (authorId, postId) => {
    if (!isUserLoaded) return; // Evita enviar comentários antes de carregar os dados do usuário
  
    try {
      const addedComment = await addCommentOnPost(authorId, postId, formValues.content);
  
      const commentData = {
        id: addedComment.id,
        content: addedComment.commentText,
        userId: addedComment.userId,
        imgProfile: infosUser.imgProfile, // Garantido que infosUser já foi carregado
        nomeProfile: infosUser.nomeEmpresa,
        timestamp: addedComment.createdAt,
      };
  
      setPrevComment((prevComments) => [commentData, ...prevComments]);
      setFormValues({ content: "" });
    } catch (error) {
      console.error("Erro ao adicionar comentário:", error);
    }
  };


  return displayedPosts && displayedPosts.length > 0 ? (
    displayedPosts.map((post, index) => (
      <motion.div
        className="post myPost"
        id={`${post.id}-${index}`}
        key={`${post.id}-${index}`}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}>
        <fieldset className="myPost__content">
          <div className="avatar">
            <div className="avatar-profile">
              <div className="avatar-image">
                <img alt="Imagem de Perfil" src={post.imgProfile === "pattern" ? Image1 : post?.imgProfile} />
              </div>
              <div className="avatar-info">
                <p>{post?.nomeEmpresa || infosUser.nameUser}</p>
              </div>
            </div>
          </div>

          {post.imgUrl &&
            <div className="post__content__image">
              <img alt={post.nameImgUrl} src={post.imgUrl} />
            </div>
          }

          <div className="content">
            <div className="reaction">
              <Like key={post.id} like={post} />
              <button
                onClick={() => toggleComments(`${post.id}-${index}`)}
                className="reaction__btn star">
                {comment === `${post.id}-${index}` ? <PiChatCircleFill /> : <PiChatCircle />}
                {post.commentsCount > 0 &&
                  <p>{post.commentsCount}</p>
                }
              </button>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`/${post.username}`}
                className="reaction__btn star"><PiUserFocusFill /></Link>
            </div>

            <div ref={postRef} key={`${post.id}-${index}`}>
              <p><b>{post.title}</b></p>
              <p>{removeTextRegex(post.content)}</p>
            </div>
          </div>

          <div className="tags">
            {Object.keys(post.tags).map((tag) => (
              <p key={`${post.id}-${tag}`}>#{tag}</p>  // Combina post.id e tag para garantir unicidade
            ))}
          </div>

          {comment === `${post.id}-${index}` &&
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="comment">
              <div className="comment__sendComment">
                <textarea onChange={handleRegulateTextarea} value={formValues.content} placeholder="Digite seu comentário" />
                <button onClick={() => sendComment(post.authorId, post.id)}>{<MdSend />}</button>
              </div>
              <div className="comment__viewComment">
                  <Comment
                    key={post.id}
                    authorId={post.authorId}
                    postId={post.id}
                    nameUser={infosUser.nameUser}
                    imgProfile={infosUser.imgProfile}/>
              </div>
            </motion.div>
          }

        </fieldset>
      </motion.div>
    ))
  ) : (
    <Loading />
  );
};

export default FetchAllPost;
