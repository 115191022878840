import "./homeLink.css";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { ButtonLink } from "../../components/button/button";
import { useEffect, useState } from "react";
import { fetchUserCategory, fetchUserData } from "../../firebase/docs";
import { useAuth } from "../../contexts/contexts";
import { FaThList } from "react-icons/fa";
import { motion } from "framer-motion";
import Loading from "../../components/loading/loading";
import ProfileCard from "../../components/card/profile";

const HomeLink = () => {

    const {mobile} = useAuth();

    const location = useLocation();
    const path = location.pathname.split("/").pop();
    const username = decodeURIComponent(location.pathname).split("/")[1];

    const [user, setUser] = useState({});
    const [category, setCategory] = useState([]);
    const [load, setLoad] = useState(true);
    const [noUser, setNoUser] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    // Variantes para animação
    const sidebarVariants = {
        open: {
        x: 0, // Posição final
        transition: { type: "spring", stiffness: 300, damping: 30 },
        },
        closed: {
        x: "-100%", // Fora da tela (lado direito)
        transition: { type: "spring", stiffness: 300, damping: 30 },
        },
    };

    useEffect(() => {
        const fetchData = async () => {

            try {
                // Buscar dados do usuário
                const data = await fetchUserData(username);
                const categoryData = await fetchUserCategory(data.uid);                     
                setUser(data.userData);
                setCategory(categoryData.categorias);
                
                if (path || username) {
                    setIsOpen(false);
                }
                
            } catch (err) {
                console.error("Erro ao buscar os dados:", err);
            } finally {
                setLoad(false);
            }
        };
        fetchData();
    }, [username, path]);

    if (load) return <Loading />;

    return (
        <div className="homeLink">
            {!noUser ? (
                <>
                    {!mobile &&
                        <div className="class">
                            <motion.div 
                                className="class__imgProfile"
                                initial={{scale: 0}}
                                animate={{scale: 1}}
                                exit={{scale: 0}}>
                                <img src={user.imgProfile} alt={`${user.nomeEmpresa}`}/>
                            </motion.div>
                            <div className="homeLink__title">
                                <Link to={`/${username}`}>
                                    {user.nomeEmpresa}
                                </Link>
                            </div>
                            {category.map((cat) => (
                                <ButtonLink
                                    key={cat.id}
                                    to={cat.id}
                                    value={cat.id}
                                    className={cat.id === path ? "active__class--item" : "class--item"}
                                />
                            ))}
                        </div>
                    }
                    {mobile && 
                        <motion.div 
                            variants={mobile && sidebarVariants}
                            initial={mobile ? "open" : "closed"}
                            animate={isOpen && mobile ? "open" : "closed"} 
                            className={mobile ? "class__mob" : "class"}>
                            <div className="class__imgProfile">
                                <img src={user.imgProfile} alt={`${user.nomeEmpresa}`}/>
                            </div>
                            <div className="homeLink__title">
                                <Link to={`/${username}`}>
                                    {user.nomeEmpresa}
                                </Link>
                            </div>
                            {category.map((cat) => (
                                <ButtonLink
                                    key={cat.id}
                                    to={cat.id}
                                    value={cat.id}
                                    className={cat.id === path ? "active__class--item" : "class--item"}
                                />
                            ))}
                            <div className="class__height"></div>
                        </motion.div>
                    }
        
                    <div className="class__result">
                        {path === username ? 
                            <ProfileCard
                                imgProfile={user.imgProfile}
                                nameImgProfile={user.nameImgProfile}
                                nomeEmpresa={user.nomeEmpresa}
                                tipoEmpresa={user.tipoEmpresa}
                                website={user.website}
                                emailContato={user.emailContato}
                                obs={user.observacoesAdicionais}
                                func={user.horarioFuncionamento}
                                imageFoc={user}
                                instagram={user.instagram}/> : <Outlet />
                        }
                    </div>
        
                    {mobile && 
                        <div className="homeLink__mobile__nav">
                            <button onClick={() => setIsOpen(!isOpen)}>
                                <FaThList />
                            </button>
                        </div>
                    }
                </>
    
            ) : (
                <div className="noUser">
                    <i>{window.location.href}</i>
                    <h1>O usuário({path}) <span>não existe!</span></h1>
                </div>
            )}
        </div>
    );
}

export default HomeLink;