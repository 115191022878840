import { Helmet } from "react-helmet-async";
import "./aparencia.css";
import Colors from "./colors/colors";
import Layout from "./layout/layout";
import CardPreview from "./card-preview/cardPreview";
import { useEffect, useState } from "react";
import { doGetDocumentOnStorage, doUpdateForm } from "../../../../firebase/docs";
import Loading from "../../../../components/loading/loading";
import { MdCheck } from "react-icons/md";
import { useAuth } from "../../../../contexts/contexts";

// Definição dos templates pré-configurados
const TEMPLATES = {
    desenholy: {
        background: "#161925",
        cards: "#1b1e2c",
        border: "#1f2332",
        letter: "#e5e5e5",
        letterButton: "#e5e5e5",
        details: "#06AED5",
        title: "#EE5622",
    },
    oceano: {
        background: "#0D1321",
        cards: "#1D2D44",
        border: "#3E5C76",
        letter: "#F0EBD8",
        letterButton: "#F0EBD8",
        details: "#748CAB",
        title: "#F1EDDC",
    },
    claridade: {
        background: "#393E41",
        cards: "#D3D0CB",
        border: "#E7E5DF",
        letter: "#000000",
        letterButton: "#0D1321",
        details: "#44BBA4",
        title: "#e15d4c",
    },
    noite: {
        background: "#000103",
        cards: "#333138",
        border: "#515052",
        letter: "#FFFFFA",
        letterButton: "#0D1321",
        details: "#21A0A0",
        title: "#F9DB6D",
    },
    natureza: {
        background: "#3C493F",
        cards: "#7E8D85",
        border: "#B3BFB8",
        letter: "#F0F7F4",
        letterButton: "#000000",
        details: "#A2E3C4",
        title: "#C9EDDC",
    },
    floresta: {
        background: "#001011",
        cards: "#3B4449",
        border: "#001011",
        letter: "#FFFFFC",
        letterButton: "#001011",
        details: "#BFE060",
        title: "#6CCFF6",
    },
    rosada: {
        background: "#DA5A9A",
        cards: "#FF6D90",
        border: "#FF909A",
        letter: "#DDE8F3",
        letterButton: "#333333",
        details: "#EBA1FF",
        title: "#FFE08D",
    },
};

const Aparencia = () => {
    const { mobile } = useAuth();
    
    // Estados para os dados do tema
    const [template, setTemplate] = useState("desenholy");
    const [colors, setColors] = useState(TEMPLATES.desenholy);
    
    const [layout, setLayout] = useState({
        gap: 8,
        borderWidth: 1,
        borderRadius: 4,
        padding: 8,
        borderType: "solid",
    });

    const [texts, setTexts] = useState({
        titleSize: 35,
        textSize: 13,
        lineHeight: 1.2,
    });

    // Estados para controle de carregamento e submissão
    const [isLoading, setIsLoading] = useState(true);
    const [isCustomizing, setIsCustomizing] = useState(false);

    // Carrega os dados do tema ao montar o componente
    useEffect(() => {
        const fetchTemplateData = async () => {
            try {
                const data = await doGetDocumentOnStorage();
                if (data?.template) {
                    const { name, color, style, text } = data.template;
                    setTemplate(name || "desenholy");
                    setColors(color || TEMPLATES.desenholy);
                    setLayout(style || {
                        gap: 8,
                        border: 1,
                        borderRadius: 4,
                        padding: 8,
                        borderType: "solid",
                    });
                    setTexts(text || {
                        titleSize: 35,
                        textSize: 13,
                        lineHeight: 1.2
                    });
                }
            } catch (error) {
                console.error("Erro ao carregar o tema:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTemplateData();
    }, []);

    // Atualiza as cores quando o template é alterado
    useEffect(() => {
        if (!isCustomizing && TEMPLATES[template]) {
            setColors(TEMPLATES[template]);
        }
    }, [template, isCustomizing]);

    // Verifica se o tema foi personalizado manualmente
    useEffect(() => {
        if (isCustomizing && template !== "personalizado") {
            setTemplate("personalizado");
        }
    }, [colors, isCustomizing, template]);

    // Função para atualizar uma cor específica
    const updateColor = (key, value) => {
        setIsCustomizing(true);
        setColors((prev) => ({ ...prev, [key]: value }));
    };

    // Função para submeter o tema atualizado
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataToSubmit = {
                template: {
                    name: template,
                    color: colors,
                    style: layout,
                    text: texts,
                },
            };
            await doUpdateForm(dataToSubmit);
            alert("Tema atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar o tema:", error);
        }
    };

    if (isLoading) return <Loading />;
    return (
        <form className="theme" onSubmit={handleSubmit}>
            <Helmet>
                <title>Tema - Desenholy</title>
            </Helmet>

            <div className="theme__grid">
                <CardPreview 
                    colors={colors}
                    layout={layout}
                    texts={texts}
                />
            </div>

            <div className="theme__grid">
                <Colors 
                    template={template}
                    setTemplate={setTemplate}
                    colors={colors}
                    updateColor={updateColor}
                />
                
                <Layout 
                    colors={colors}
                    layout={layout}
                    setLayout={setLayout}
                />

            </div>

            <div className="theme__grid__button">
                <button type="submit" className="save__theme">
                    {mobile ? <MdCheck /> : "Salvar"}
                </button>
            </div>
        </form>
    );
};

export default Aparencia;