import "./confirm.css";
import { getUnicode } from "emoji-dictionary";
import { motion } from "framer-motion";

const Confirm = ({ message, onOk, onCancel, txtOk, txtCancel, emoji }) => {
    return (
        <>
            {/* Fundo escurecido */}
            <motion.div 
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                className="confirm-alert__overlay" onClick={onCancel}></motion.div>

            {/* Modal centralizado */}
            <motion.div 
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                className="confirm-alert">
                <div className="confirm-alert__text">
                    <motion.div 
                        initial={{scale: 0}}
                        animate={{scale: 1}}>{getUnicode(`${emoji}`)}</motion.div>
                    <p>{message}</p>
                </div>
                <div className="confirm-alert__button">
                    <button onClick={onCancel}>{txtCancel}</button>
                    <button onClick={onOk}>{txtOk}</button>
                </div>
            </motion.div>
        </>
    );
};

export default Confirm;
