
import "./post.css";
import { motion } from "framer-motion";
import { RiSendPlane2Fill } from "react-icons/ri";
import { FaImage } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { savePost, uploadImagePost } from "../../../../../../firebase/post";
import { doGetDocumentOnStorage } from "../../../../../../firebase/docs";
import Message from "../../../../../../components/alerts/message";

const Post = () => {
    
    //consts
    const [image, setImage] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [tags, setTags] = useState({});
    const [edit, setEdit] = useState(false);
    const [infoPost, setInfoPost] = useState({});
    const [savedPost, setSavedPost] = useState(false);

    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await doGetDocumentOnStorage();
            setInfoPost({
                imgProfile: data?.imgProfile,
                name: data.nomeEmpresa,
                username: data.username
            });
        }
        fetchData();
    }, [formValues]);

    const handleRegulateTextarea = (e) => {
        const { id, value } = e.target;
        const textarea = e.target;
        const currentValue = textarea.value;
    
        // Atualiza o campo 'content' no formValues
        setFormValues((prevFormValues) => ({
            ...prevFormValues, // Mantém os outros campos intactos
            [id]: value, // Atualiza o campo específico (ex. 'title' ou 'content')
            content: currentValue, // Atualiza 'content' com o valor atual do textarea
        }));
    
        // Extração das tags
        const exTags = {};
        const regex = /#(\w+)/g; // Encontra as palavras com #
        let match;
        while ((match = regex.exec(currentValue)) !== null) {
            exTags[match[1]] = true; // Adiciona a tag ao objeto
        }
    
        // Atualiza o estado de tags
        setTags(exTags); 
    
        // Ajusta a altura do textarea dinamicamente
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
    
        if (file) {
            // Verificar o tipo do arquivo (somente imagens)
            const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
            if (!validImageTypes.includes(file.type)) {
                alert("Por favor, selecione uma imagem válida (JPEG, PNG, GIF ou WEBP).");
                return;
            }
    
            // Verificar o tamanho do arquivo (máximo 5MB)
            const maxSizeInMB = 7;
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // 7MB
            if (file.size > maxSizeInBytes) {
                alert(`A imagem deve ter no máximo ${maxSizeInMB}MB.`);
                return;
            }

            // Ler o arquivo e armazenar no estado
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage({
                    preview: e.target.result,
                    file: file,
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const resetImageChange = () => {
        setImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const dataForm = {
            imgProfile: infoPost?.imgProfile || "pattern",
            nomeEmpresa: infoPost.name,
            title: formValues.title?.trim(),
            content: formValues.content?.trim(),
            username: infoPost.username,
            tags: { ...tags },
        };

        if (image?.file) {
            const imgUrl = await uploadImagePost(image.file);
            const nameImgUrl = image.file.name;
            console.log("Url da imagem enviado", imgUrl);
            dataForm.imgUrl = imgUrl;
            dataForm.nameImgUrl = nameImgUrl;
        } else {
            const imgUrl = "";
            const nameImgUrl = "";
            dataForm.imgUrl = imgUrl;
            dataForm.nameImgUrl = nameImgUrl;
        }
        
        if (!dataForm.title || !dataForm.content) {
            console.error("Os campos 'title' e 'content' são obrigatórios.");
            return;
        }
    
        try {
            await savePost(dataForm);
            setSavedPost(true);
        } catch (error) {
            console.error("Erro ao salvar o post:", error.message);
        } finally {
            setEdit((prev) => !prev);
            setTimeout(() => {
                setSavedPost(false);
            }, 5000);
        }
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setEdit((prev) => !prev);
    }

    return (
        <motion.div
            className="post"
            initial={{scale: 0}}
            animate={{scale: 1}}
            exit={{scale: 0}}>
                <button className="post__addPost" onClick={handleEdit}>{!edit ? <b>Adicionar Post</b> : <b>Cancelar</b>}</button>

                {savedPost &&
                    <Message 
                        title="Concluído!"
                        className="alert-success"
                        text="Sua postagem foi adicionada! É necessário atualizar a página!"
                        emoji='smile'/>
                }

                {edit && 
                    <motion.div
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        exit={{scale: 0}}>
                        <fieldset className="form__section post__content">
                            {image &&
                                <motion.img 
                                    initial={{scale: 0}}
                                    animate={{scale: 1}}
                                    exit={{scale: 0}}
                                    transition={{duration: 0.3}}
                                    className="post__image" alt="Name Here" src={image.preview}/>
                            }

                            <input 
                                className="post__title"
                                value={formValues.title || ""}
                                onChange={(e) => setFormValues(prev => ({...prev, title: e.target.value}))}
                                placeholder="Qual será o titulo?"/>
                            <textarea 
                                className="post__text"
                                value={formValues.content || ""}
                                placeholder="Já fez sua postagem da semana?" 
                                onChange={handleRegulateTextarea}/>
                        </fieldset>
                        <fieldset className="form__section post__button">
                            <button className="post__btn post__button--sendPost" onClick={handleSubmit}> <RiSendPlane2Fill /> </button>
                            <input className="post__btn__input" type="file" ref={fileInputRef} accept="image/*" onChange={handleImageChange} name="image-post" id="image-post" />
                            <label className="post__btn post__button--addImage" htmlFor="image-post"
                                onClick={(e) => {
                                    if (image) {
                                        e.preventDefault();
                                        resetImageChange();
                                    }
                                }}> {!image ? <FaImage /> : <MdClose />} </label>
                        </fieldset>
                    </motion.div>
                }
        </motion.div>
    );
}

export default Post;