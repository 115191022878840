import "./home.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/header/header";
import { PiDatabase, PiDatabaseFill, PiStarFour, PiStarFourFill } from "react-icons/pi";
import { useAuth } from "../../../contexts/contexts";
import { TbUser, TbUserFilled } from "react-icons/tb";
import { BiCollection, BiSolidCollection } from "react-icons/bi";
import { useEffect, useState } from "react";
import { doGetDocumentOnStorage } from "../../../firebase/docs";
import { MdOutlinePalette, MdPalette } from "react-icons/md";

export default function Home() {

  // AuthProvider
  const { mobile } = useAuth();

  // useState
  const [userProfileImg, setUserProfileImg] = useState({});

  // location
  const location = useLocation();
  const path = decodeURIComponent(location.pathname).split("/").pop();
  const pathClass = decodeURIComponent(location.pathname).split("/")[2];
  const navigate = useNavigate();

  // useEffect
  useEffect(() => {
    const fetchProfileImg = async () => {
      const data = await doGetDocumentOnStorage();
      setUserProfileImg({
          img: data.imgProfile,
          name: data.nomeEmpresa
      });
    }
    fetchProfileImg();
  }, []);

  const perfilBtn = () => {
    navigate("/perfil");
  }

  const spaceBtn = () => {
    navigate("pagina-inicial");
  }
  
  const serviceBtn = () => {
    navigate("servicos")
  }

  const themeBtn = () => {
    navigate("aparencia")
  }

  const dataBtn = () => {
    navigate("dados")
  }

  return (
    <section className="container">
      <div className="container__lmr container__left">
        <Header />
      </div>

      <div className="container__middle">
        <Outlet />
      </div>

      <div className="container__lmr container__right">
        
        {/* Coloque os conteúdos da direita aqui */}
        {mobile && 
          <div className="flow__footer__step row">

            <button onClick={themeBtn} disabled={path === "aparencia" || pathClass === "aparencia"}>
              {path === "aparencia" || pathClass === "aparencia" ? <MdPalette /> : <MdOutlinePalette /> }
            </button>
            
            <button onClick={dataBtn} disabled={path === "dados" || pathClass === "dados"}>
              {path === "dados" || pathClass === "dados" ? <PiDatabaseFill /> : <PiDatabase /> }
            </button>

            <button onClick={spaceBtn} disabled={path === "pagina-inicial"} className={path === "pagina-inicial" && "spinnerStarSpace"}>
              {path === "pagina-inicial" ? <PiStarFourFill />  : <PiStarFour /> }
            </button>
            
            <button onClick={serviceBtn} disabled={path === "servicos" || pathClass === "servicos"}>
              {path === "servicos" || pathClass === "servicos" ? <BiSolidCollection /> : <BiCollection />}
            </button>
            
            <button className={path === "perfil" ? "profile-marked" : "profile-mark"} disabled={path === "perfil"} onClick={perfilBtn}>
              <img src={userProfileImg?.img} alt={userProfileImg?.name} no-repeat/>
            </button>

          </div>
        }
      </div>
    </section>
  );
}
